<template>
  <!--  3 Natia-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">
      Sometimes I feel like I am weak because I am very emotional. But I can also be very strong, especially when it
      comes to helping people: my family, my neighbours and my friends. I do catch myself thinking I am weak, but I’ve
      been told I am a strong woman. I’ve had to overcome a lot of difficulties on my own. Kakha is my husband and he is
      more talented than me, but he is shy. I pushed him to come out of his shell. For example, I encouraged him to run
      in the local government elections and he was elected as a representative. Kakha often says that without me he
      wouldn’t have been able to do that. I have a very busy schedule but I am always happy. I love being busy. When I
      am sick, I am forced to slow down and I don’t like that. </p>
    <blockquote data-aos="fade-up">
      The problem is that in Georgia popularity and reputation is everything.
    </blockquote>
    <p data-aos="fade-up" class="justify">
      When I was a little girl, I had a lot of friends but things changed when I became a teenager. I grew into someone
      who was more timid. I had a deep desire to socialise with my peers, but I was too shy. Our teachers had the habit
      of always giving good grades to the same students, even when they didn’t deserve them. It was always the children
      who came from popular and wealthy families. Unfortunately that’s how things work. My mother did not fight for me
      to be included within those circles. My mother didn’t particularly enjoy participating in our town’s social
      activities, she already had a lot of friends in Tbilisi. The problem though, is that in Georgia popularity and
      reputation is everything. If someone is not popular, they are not taken into consideration. They become somewhat
      of an outcast, and that is a very difficult thing to be in a community based society.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch duo landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify"> My mother never interfered in my life, but it made me stronger. I’ve had to
      learn how to
      fight for myself. My mother was very educated, she graduated from the university. She was the head of a factory.
      But she didn’t like to show off. My parents got married quite late. My father was 40 years old and my mother was
      37 years old. This is very rare in Georgia, even for now, but especially at the time. My parents would let me go
      anywhere I wanted. They trusted me. After 9th grade, I overcame my shyness and started making a lot of friends
      again. It became very important for me to also include the other kids who were usually cast aside, like I had been
      before. Some parents were very invested in their children’s education, my parents were not as much but it made me
      stronger. They’ve made into the strong and caring person that I am today. I believe I’ve had a very happy and
      blessed life because of my upbringing.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">After I finished high school, I moved to Batumi for my studies and stayed with
      some relatives.
      I’ve been supporting my family financially since I graduated. When I got married, my husband was unemployed and I
      also supported my sister so she could study in Tbilisi. This was the only city where she could pursue a career in
      graphic design. Eka is my big sister, but I started working before her. She’s always been an artist. She has a
      true gift for painting. Unfortunately, I had to stop supporting her after I got married because I got pregnant
      straight away. I still blame myself for my sister’s situation, that she was not able to finish her studies and
      graduate. She could have stayed in Tbilisi and lived there if she had finished. But my sister does not blame me.
      Now she lives in Lanchkhuti and works as an art teacher in the same school as me. She is married and lives with
      her in-laws. She still has a strong passion for art, but she doesn’t paint as much as she used to since her wifely
      duties keep her busy.</p>
    <blockquote data-aos="fade-up">In Georgia, there is a belief that it’s shameful for men to do housework.
    </blockquote>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">In Georgia, women take on a lot of responsibilities for themselves. My husband
      is supportive,
      but in general Georgian men are not like that. A wife can become quite nervous if her husband is not happy with
      something, like for example with the way she cooks. Kakha never asks me for anything. If I am busy, he will take
      care of the household chores himself and my sons participate too. I am sure that my sons will treat their wives
      the way Kakha treats me. I believe it’s better this way. I believe women take on more responsibilities maybe
      because men are lazier. Kakha never lets me work in the yard, but in other households women are the ones doing
      everything: yard work, housework and they are earning money. These women don’t get help from their husbands
      because in Georgia, there is a belief that it’s shameful for men to do housework. This is why I don’t tell
      everyone that Kakha helps me around the house. But it is precisely because he helps me that I have time to do
      other things that I enjoy, like taking care of my students and lesson planning. This makes me better at my job and
      a better contributor in my community. My job is to educate Georgian youth so it’s very important that I have the
      time to do it well. If my husband were rich, I would still be working because I enjoy teaching so much. I also
      feel very blessed for the family I have built with my husband.</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">A lot of programs on TV say that Georgian women have rights, that Georgia has
      gender equality.
      But there’s a saying in Georgia: ‘I know my woman is in the kitchen’. A lot of men say this. I think they don’t
      love their wives enough. If I ever heard Kakha say this, I would cry. I think people who think like this will
      never change. These days, things are getting much better though. Women have realised that they have their own
      rights that they can fight for. There are a lot of organisations in Georgia that are working on gender equality.
      You can see that the children who come from families where both parent contribute are different. They seem
      happier. Most of my students don’t have their mothers at home. They are working abroad - Turkey, Russia, Greece,
      and even France. This doesn’t concern the men, mostly because there is more work for women as housekeepers or
      nannies.</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-9.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">The first time I saw non-soviet foreigners, I thought they were from another planet!
      They really looked like aliens to me.
    </blockquote>
    <p data-aos="fade-up" class="justify">For me, these changes will take a long time and not everyone will change. But
      when I started
      teaching, you would never see fathers implicated in their children’s lives. Now, I see men taking their kids to
      school or to birthday parties. Under the communist regime everything was bad. Things are improving since the fall
      of the Soviet Union. Men and women who are older are still under the influence of communism, they think women
      should not be allowed in the army, not allowed to become pilots etc. The soviets believed a woman’s place was in
      the house. Things are changing with the influence of Europe. You know what is funny, the first time I saw
      non-soviet foreigners, I thought they were from another planet! They really looked like aliens to me. The next
      generation will be much better than us. A lot of my previous students are trying to push the boundaries of gender
      norms. For example, I have former male students that are now studying at the Tbilisi Culinary Academy, a
      profession traditionally women-led in Georgia. And my previous student who is a girl is studying to become a
      pilot. Things are changing step-by-step.</p>
    <div class="pic-block">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-10.jpg')"
          :alt="womenName">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Natia',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
