<template>
  <!--  2 Khatuna-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">
      I was born in Poti 41 years ago. I grew up in this city. I’ve spent all my life here. This is my hometown, and for
      me that means everything. I know everyone here. I know every tree and every stone. I come from a big family. I
      grew up with my father, my mother, my brother, my grandmother and my unmarried aunt - so at home, I had three
      moms. My childhood was interesting. Two of my uncles were killed during World War II, they were 19 and 21 years
      old. The youngest joined the army voluntarily. He ran away from home to join his brother who was drafted in the
      war. Only one official death note was sent, so we considered that maybe one of the brothers was lost. My
      grandparents waited their entire life for one of their sons to come home. When the USSR collapsed many people
      reappeared, and we thought he would too. I also feel like I’ve waited my entire life for my uncle to come home.
    </p>
    <blockquote data-aos="fade-up">Hope is always the last thing that dies.</blockquote>
    <p data-aos="fade-up" class="justify">Ten years ago, a friend of our family finally told us that my uncle had died
      and he had been
      buried. This friend had hidden this truth from us because he didn’t want to hurt my grandparents, he waited until
      my grandmother finally passed away to tell us. Hope is always the last thing that dies. My mom’s father was also
      killed in World War II. When he left for the war, my maternal grandmother was pregnant. So when my mother was
      born, she sent a letter to her husband. She lived the rest of her life without knowing if the letter had ever been
      received. My dad was an engineer and mom was chief accountant, but I grew up hearing all these stories. I have
      letters from the war from both my uncles.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">All of a sudden, we had no more electricity, no more food, and bombs were falling on
      our heads.
    </blockquote>
    <p data-aos="fade-up" class="justify">When the Georgian Civil War broke in 1992, I was 14 years old. It was very
      shocking to me
      because I had always thought we lived in a peaceful environment. All of a sudden, we had no more electricity, no
      more food, and bombs were falling on our heads. We had the feeling that nothing would ever change, we had no hope
      and people were being killed. It’s around that time that I finished high school, but we didn’t know if I was going
      to be able to go to Tbilisi to study. It was a dangerous city at the time. Even travelling there would take a
      really long time. My mom decided that I should start studying in Poti. I studied to be an accountant, even if I
      hated it, but at least I thought it would lead me to something. Finishing school without any professional
      opportunities would be worse than ending up as an accountant. When the war ended, I started a masters program in
      management in Kutaisi. To this day, I am not satisfied with my academic career. My dream was to study in
      Tbilisi.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">I got married at the age of 16.</blockquote>
    <p data-aos="fade-up" class="justify">In 2008, Poti was bombed again. We had to leave without knowing where it was
      safe. We found
      refuge in the region of Guria, in the village of Chokhatauri. When it was time to leave, I asked myself, ‘What is
      the best way to leave? Should I run? Should I ride a bike?’. My uncle sent us a car. We were 9 in that car. When
      it came time to pack, I took the most expensive trousers that I had. In these types of situations, people cannot
      think clearly. I took all my most expensive clothes, and it’s only after that I realised that they were not
      practical at all, so we had to go into a second hand store to buy new clothes. I also left all my jewellery
      behind. During that time, I was given the opportunity to work with foreign journalists. We would interview people
      who had lost their sons. Today when I hear the word ‘war’, there are so many things that come to mind…</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">As a woman, you need to have more strength, more education, more motivation because
      if you don’t, it will not be easy to find your place in society.
    </blockquote>
    <p data-aos="fade-up" class="justify">I got married at the age of 16. At that time, Georgian girls used to get
      married at a very young age. I was only 18 years old when I got pregnant, but both my parents and my in-laws were
      supportive and allowed me to continue studying. I come from a family that appreciates education. My mother worked
      as a chief accountant, all her coworkers were men and everyone respected her. My brother doesn’t have as many
      diplomas as I do. As a woman you need to be stronger, you need to be more educated, you need to have more
      motivation than everybody else. If you don’t have all these things, it will not be easy to find your place in
      society. If you are stuck at home, you can never truly be part of society. My family would have never allowed
      that. The situation was the same with my in-laws; my mother in-law was an engineer. From the earliest years of my
      childhood, I knew that I had to work very hard. This was not only my choice. I had to obey my parents. For
      example, my mom made me take piano lessons when I actually wanted to play football. I did this to please her. I
      was supposed to study the piano for a total of 7 years. But after 5 years, I began to lie to my mom about going to
      piano lessons. Instead, I would just go to the café with the 1 lari my mom would give me to buy khatchapuri. Being
      a girl in Georgia doesn’t mean that you have to do everything your parents tell you to do. I still took my
      mother’s advice about studying to be an accountant because it gave me more opportunities than if I had studied
      something else, like history.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      In my family, women are leaders. I am surprised when women from the villages say they cannot
      find their place in their community because this has not been my case. In my family, I would go as far as saying
      that I had more privileges than my brother. When he did things that my parents could be mad about, he would say I
      had done them because he knew my parents would not get mad at me. Even amongst my peers, everyone listened to me.
      I was definitely a leader amongst my friends. It’s true that in the villages there are not many opportunities.
      Maybe these communities would benefit from a youth centre or a women’s centre. But in my opinion, you don’t need
      to be educated to make a positive change. You don’t need to have an education to be a leader. Yes, education is
      important for women, but even if they don’t have that they can still learn something and make a positive change. I
      understand that it is not easy, but for me, I think if someone has a strong wish to do something, they can achieve
      it no matter their circumstances. The world is constantly evolving. Social norms are constantly changing. But for
      myself, I did not face any problems getting a job, getting an education, even though I am a woman. I’ve never felt
      any type of disrespect because I am a woman.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      Maybe in villages it still happens, maybe there are some families who
      pressure their daughters into getting married because they believe there are no other opportunities for women. But
      I cannot understand this. I ask myself, is this really happening in Georgia? I am not saying that it isn’t a
      reality, but it has not been my experience. All I see are families supporting their daughters in becoming
      educated, gaining more knowledge, more than boys actually because it’s harder for women. Even in my house, my dad
      always helped my mom with the different chores. They both worked very hard and they both shared all the
      responsibilities. When my mother felt ill, my dad was able to cook us dinner. When I hear that this is not the
      case in other families, I feel lucky that I wasn’t in this situation. But at the same time, I don’t like the idea
      of a man having to take care of everything. I feel bad when it happens.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <blockquote data-aos="fade-up">Women have a responsibility towards Georgia to make this country better</blockquote>
    <p data-aos="fade-up" class="justify">I always tell the girls who get married at a young age and have kids, who feel
      like they can’t
      work: ‘you can overcome all obstacles’. When we organize different workshops with girls, I tell them that they
      have enormous potential and that they can reach whatever goal they set for themselves. I really don’t want them to
      feel like they can’t do anything just because they are women. I often hear that we need more women in politics,
      but if a woman doesn’t have the right experience, I will not support her. I wish for all Georgian women to be more
      hardworking, more motivated, and to fight more bravely. My hope is that they will not abandon this country. If
      they study abroad, I hope they will come back and use their knowledge to make our country better. Women have a
      responsibility towards Georgia to make this country better. It is not an easy time for my country, but it is my
      responsibility as a Georgian citizen. I think I could be more successful elsewhere, but I would not be happy
      because what I am doing is more important. Being a role model for young people is something very special. I find
      myself very happy here despite all the difficulties. This is one of the reasons why I trust Salome*, she could
      have stayed in Europe, but I think she felt like she had the responsibility to come back here.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify"><i>*Salome Zourabichvili is the current president of Georgia. She was elected
      in 2018 but the
      result of the election has been a topic of controversy</i></p>
  </div>
</template>

<script>
export default {
  name: 'Khatuna',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
