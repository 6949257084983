<template>
  <!--  4 Salome-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">I was born in Savane and I’ve always lived here. My parents want me to pursue
      a higher education degree in a city because they know that in the village, there won’t be a lot of opportunities
      for me. I also have a strong desire to accomplish something meaningful with my life. This is why I take part in
      every external opportunity that comes my way - like national competitions or exchanges. It’s the only way for me
      to meet new people and be confronted with new possibilities. For me, it is synonymous with freedom.</p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">I want to be a Georgian teacher when I grow up. Teachers have the enormous
      responsibility of educating future generations. But a teacher’s influence is not limited to knowledge. Sometimes
      teachers can become true role models for their students. Children are very much influenced by their teachers. So I
      have in mind that one day, I must be a kind and communicative adult. I believe it’s through teaching children to
      love themselves that they will learn how to reach their full potential. I am passionate about Georgian literature
      and Georgian language in general. I want to preserve our culture, and our language is extremely connected to our
      history.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      I want to work with minority communities that live in Georgia. I think that it’s within this context that I will
      learn the most about myself, through new challenges and new encounters. It’s not the same thing when you’re
      teaching in a Georgian community. Minorities that are living in our country need our language more than we do.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">Tolerance is the thing that humans need the most. It’s important to respect
      everyone’s culture. If these minority communities do not wish to assimilate to our religion, I personally do not
      see it as a problem. There are a lot of Georgians living abroad, and I wish for them to be accepted with open arms
      in these foreign countries. So I think it should be the same here.</p>
    <blockquote data-aos="fade-up">We have no desire in repeating the same things over and over again just because these
      boys are incapable of seeing the issue.
    </blockquote>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">I believe boys and girls are equal. I even believe that sometimes girls are
      stronger than
      boys. Most teachers are women and in our country, more than 60% of students who become doctors are women. We are
      active members of this community. It is a stereotype when people say that girls cannot do certain things. It does
      not matter if I am a boy or a girl. My role is to become the best version of myself that I can be. Most girls
      think like me. There are some girls who disagree, but most of them think like this. I believe every human being
      has a desire to be treated equally.</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      Everyone wants the same chance at life. The boys that I know are not
      interested in gender equality. They are not open-minded. I think it’s because they don’t try to meet new people,
      so they are close-minded. But my friends and I, we don’t have the energy to fight with them. We have no desire in
      repeating the same things over and over again just because these boys are incapable of seeing the issue. </p>
    <blockquote data-aos="fade-up">The boys are always trying to tell us that they are better than us, but all the best
      students in the class are girls.
    </blockquote>
    <p data-aos="fade-up" class="justify">Sometimes, my class is divided between the boys and the girls, because we want
      equality but we
      don’t to start a conflict. The boys are always trying to tell us that they are better than us, but all the best
      students in the class are girls. In general, we participate more in school projects and other extracurricular
      activities. I feel like boys don’t like to show that they are invested in their education, they are afraid of
      being judged. They are afraid of being perceived as a ‘kargi bichi’ (good boy), so they behave badly and neglect
      their homework.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-9.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <blockquote data-aos="fade-up">I would like to say that we will have equality in 50 years, but I don’t think it will
      be the case. There is still too much resistance from the boys of my generation.
    </blockquote>
    <p data-aos="fade-up" class="justify">Georgia as a country is more developed now. There are international
      organisations that are
      working on the ground here. This is another way Georgians have been able to meet foreigners – this has really
      contributed to the development of our country. The government is trying to integrate more and more women into the
      public arena. I would like to say that we will have equality in 50 years, but I don’t think it will be the case.
      There is still too much resistance from the boys of my generation.</p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-10.jpg')"
          :alt="womenName">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Salome',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
