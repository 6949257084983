<template>
  <main class="women">
    <section class="woman-header text-center">
      <img class="main-pic"
           :src="require('@/assets/img/women/' + currentWomen.order + '_' + currentWomen.name.toLowerCase()  + '/pics/Women-of-georgia-' + currentWomen.name +'-1.jpg')"
           alt="">
      <div class="woman-infos">
        <h1>{{currentWomen.name}}</h1>
        <span :style="{'color': '#' + currentWomen.color}">{{currentWomen.infos}}</span>
      </div>
    </section>
    <section class="woman-content">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-8 col-md-offset-2">
            <Nutsa v-if="currentWomen.name === 'Nutsa'" :womenId="currentWomen.order "
                   :womenName="currentWomen.name"></Nutsa>
            <Khatuna v-if="currentWomen.name === 'Khatuna'" :womenId="currentWomen.order"
                     :womenName="currentWomen.name"></Khatuna>
            <Natia v-if="currentWomen.name === 'Natia'" :womenId="currentWomen.order"
                   :womenName="currentWomen.name"></Natia>
            <Salome v-if="currentWomen.name === 'Salome'" :womenId="currentWomen.order"
                    :womenName="currentWomen.name"></Salome>
            <Irina v-if="currentWomen.name === 'Irina'" :womenId="currentWomen.order"
                   :womenName="currentWomen.name"></Irina>
            <Nargizi v-if="currentWomen.name === 'Nargizi'" :womenId="currentWomen.order"
                     :womenName="currentWomen.name"></Nargizi>
            <Manana v-if="currentWomen.name === 'Manana'" :womenId="currentWomen.order"
                    :womenName="currentWomen.name"></Manana>
            <Marina v-if="currentWomen.name === 'Marina'" :womenId="currentWomen.order"
                    :womenName="currentWomen.name"></Marina>
            <Anuka v-if="currentWomen.name === 'Anuka'" :womenId="currentWomen.order"
                   :womenName="currentWomen.name"></Anuka>
            <Leila v-if="currentWomen.name === 'Leila'" :womenId="currentWomen.order"
                   :womenName="currentWomen.name"></Leila>
          </div>
        </div>
      </div>
    </section>
    <section class="story-selection">
      <div class="container">
        <div class="row text-center title">
          <div class="col-xs-12">
            <span class="subtitle">DISCOVER MORE STORIES</span>
            <h1>Continue reading</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <!--            TODO MARC <3-->
            <div class="story-selector">
              <div class="story left">
                <div class="women-infos" @click="getPreviousWomen" v-show="currentWomen.order > 1">
                  <h3>{{previousWomen.name}}</h3>
                  <span :style="{'color': '#' + previousWomen.color}">previous story</span>
                </div>
                <div class="women" @click="getPreviousWomen" v-show="currentWomen.order > 1">
                  <div class="bg"
                       :style="{'background-image': 'url(' + require('@/assets/img/women/' + previousWomen.order + '_' + previousWomen.name.toLowerCase() + '/pics/Women-of-georgia-' + previousWomen.name + '-1.jpg') + ')'}"></div>
                </div>
              </div>
              <div class="story right">
                <div class="women-infos" @click="getNextWomen" v-show="currentWomen.order < 10">
                  <h3>{{nextWomen.name}}</h3>
                  <span :style="{'color': '#' + nextWomen.color}">next story</span>
                </div>
                <div class="women" @click="getNextWomen" v-show="currentWomen.order < 10">
                  <div class="bg"
                       :style="{'background-image': 'url(' + require('@/assets/img/women/' + nextWomen.order + '_' + nextWomen.name.toLowerCase() + '/pics/Women-of-georgia-' + nextWomen.name + '-1.jpg') + ')'}"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Nutsa from '@/components/women/Nutsa.vue';
import Salome from '@/components/women/Salome.vue';
import Leila from '@/components/women/Leila.vue';
import Anuka from '@/components/women/Anuka.vue';
import Manana from '@/components/women/Manana.vue';
import Nargizi from '@/components/women/Nargizi.vue';
import Irina from '@/components/women/Irina.vue';
import Marina from '@/components/women/Marina.vue';
import Natia from '@/components/women/Natia.vue';
import Khatuna from '@/components/women/Khatuna.vue';
import { data } from '@/data';


export default {
  components: {
    Nutsa,
    Salome,
    Leila,
    Anuka,
    Manana,
    Nargizi,
    Irina,
    Marina,
    Natia,
    Khatuna
  },

  data () {
    return {
      women: data,
      switchAnimation: true,
    }
  },

  computed: {
    currentWomen: function () {
      return this.women.find(w => this.$route.params.womenName == w.name.toLowerCase())
    },
    nextWomen: function () {
      return this.women[this.currentWomen.order] || this.women[0]
    },
    previousWomen: function () {
      return this.women[this.currentWomen.order - 2] || this.women[9]
    },
  },

  mounted () {
    // console.log('Current Women : ' + this.currentWomen.name)
    // console.log('Next Women : ' + this.nextWomen.name)
    // console.log('Previous Women : ' + this.previousWomen.name)
  },

  methods: {
    getNextWomen: function () {
      const isNextWomen = this.women[this.currentWomen.order] || this.women[0]
      this.$router.push({ name: 'women', params: { womenName: isNextWomen.name.toLowerCase() } })
    },
    getPreviousWomen: function () {
      const isPreviousWomen = this.women[this.currentWomen.order - 2] || this.women[0]
      this.$router.push({ name: 'women', params: { womenName: isPreviousWomen.name.toLowerCase() } })
    }
  },

  watch: {
    '$route' () {

    }
  }

}
</script>

<style lang="scss">
  section.woman-header {
    .main-pic {
      width: 100%;

      @media (min-width: $break-desktop) {
        width: auto;
        max-height: 150vh;
        display: block;
        margin: 0 auto;
      }
    }

    .woman-infos {
      display: inline-block;
      padding: 3rem;
      background: white;
      flex-direction: column;
      transform: translateY(-30%);

      @media (min-width: $break-desktop) {
        max-height: 150vh;
        margin: 0 auto;

        h1 {
          font-size: 8rem !important;
          line-height: 8rem !important;
        }

        span {
          font-size: 2rem !important;
        }
      }

      h1 {
        font-family: 'Couture', Helvetica, Arial, sans-serif;
        margin-bottom: 0;
        font-size: 5rem;
        line-height: 5rem;
      }

      span {
        font-size: 1.5rem;
      }
    }
  }
  .spacer {
    height: 55vw;


    &.medium {
      height: 64vw;

      @media (min-width: $break-tablet) {
        height: 60vw;
      }
      @media (min-width: $break-desktop) {
        height: 59vw;
      }
    }

    &.big{
      height: 80vw;

      @media (min-width: $break-tablet) {
        height: 72vw;
      }
      @media (min-width: $break-desktop) {
        height: 70vw;
      }
    }

    @media (min-width: $break-tablet) {
      height: 52vw;
    }
    @media (min-width: $break-desktop) {
      height: 50vw;
    }

    @media (min-width: 2400px) {
      height: 49vw;
    }
  }

  section.woman-content {
    margin-bottom: 10rem;

    p {
      margin-bottom: 5rem;
      text-align: justify;
    }

    i {
      font-size: 1.3rem;
      line-height: 2.4rem;
    }

    blockquote {
      margin: 4rem 0;
      font-size: 3rem;
      line-height: 4.5rem;
      font-weight: bold;
    }

    .pic-block {
      margin: 0 -2rem 5rem;

      .pic-dispatch {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        img {
          max-width: 100%;
        }

        &.duo {
          img:last-child {
            margin-top: 2rem;
          }
        }
      }

      &.full {
        width: 100%;
        position: absolute;

        img {
          width: 100%;
        }

      }

      @media (min-width: $break-tablet) {
        margin: 0 0 5rem;

        &.full {
          left: 0;
        }
      }


      // Behaviour on desktop
      @media (min-width: $break-desktop) {

        .pic-dispatch {
          flex-direction: row;
          flex-wrap: nowrap;

          &.portrait {
            img {
              max-width: 75%;
            }
          }

          // when 2 pictures
          &.duo {
            padding: 0 2rem;

            img:last-child {
              margin-left: 2rem;
              margin-top: 0;
            }

            &.landscape {
              img {
                width: 80%;
              }
            }

            &.portrait {
              img {
                width: 70%;
              }
            }
          }
        }
      }
    }
  }

  .story-selection {
    padding: 10vh 0;
    background: $green-bg;

    .story-selector {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .story {
        cursor: pointer;
        transition: all 300ms ease-in-out;
        position: relative;


        @media (min-width: $break-desktop) {
          &:hover {
            .women .bg {
              transform: scale(1.12);
            }

            &.left {
              .women-infos {
                padding: 1.5rem 1.5rem 1.5rem 3rem;
              }
            }

            &.right {
              .women-infos {
                padding: 1.5rem 3rem 1.5rem 1.5rem;
              }
            }
          }
        }

        .women-infos {
          background: white;
          padding: 1.5rem;
          position: absolute;
          bottom: -20px;
          transform: translateY(-50%);
          white-space: nowrap;
          transition: all 300ms ease-in-out;
          z-index: 1;

          @media (min-width: $break-desktop) {
            top: 50%;
            bottom: inherit;
          }

          h3 {
            margin-bottom: 0;
          }

          span {
            display: none;
            font-family: 'Couture', Helvetica, Arial, sans-serif;
            text-transform: uppercase;
            font-size: 1rem;
            color: $green;
          }

          @media (min-width: $break-phone) {
            span {
              display: block;
            }
          }

        }

        &.left {
          text-align: left;

          .women-infos {
            left: 50%;
            transform: translateX(-50%);

            @media (min-width: $break-desktop) {
              transform: none;
              left: 90%
            }
          }
        }

        &.right {
          text-align: right;

          .women-infos {
            left: 50%;
            transform: translateX(-50%);

            @media (min-width: $break-desktop) {
              transform: none;
              left: inherit;
              right: 90%;
            }
          }
        }

        .women {
          position: relative;
          width: 35vw;
          background: white;
          max-width: 400px;
          overflow: hidden;

          @media (min-width: $break-desktop) {
            width: 25vw;
          }

          .bg {
            transition: all 300ms ease-in-out;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
          }

          @media (min-width: $break-phone) {
          }
          @media (min-width: $break-tablet) {
          }
          @media (min-width: $break-desktop) {
          }
          @media (min-width: $break-desktophd) {
          }

          &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (259 / 219) * 100%;
          }

          > .inner {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }
</style>
