<template>
  <!--  9 Anuka-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">I grew up in Lanchkhuti, a small town in the western part of Georgia in the
      region of Guria. In school, I was always considered to be a rebel kid. I often had personal views on any kind of
      topic and the teachers didn’t appreciate that. They told me I was too opinionated, that I talked too much or that
      I asked too many questions. They never really explained to me why I had to agree with them. I believe this is
      truly how the communist regime has hurt my country, still to this day no one is encouraged to think for themselves
      and our schools are stuck in that pattern. I received my primary education from my father. He’s the one who taught
      me to think outside the box, to question everything and to form my own opinions. He wanted his daughters – my
      sister and I - to be educated so that we could experience everything in life. </p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">My dad was not a typical Georgian father, and by typical Georgian father I
      mean a man who is always tired, whose only role is to provide financially for his family, someone who doesn’t
      communicate with his children. My mother is my role model of a strong woman. She was the one who had to work when
      my dad was going through hard times. At home, my parents were equals. Today, I look for a man who will be like my
      father. I am single at the moment, but I want someone with whom I’ll be able to enjoy an equal partnership like my
      parents did with each other. I’m starting to understand that this is rare. </p>
    <blockquote data-aos="fade-up">Feminism has a bad reputation in Georgia.</blockquote>
    <p data-aos="fade-up" class="justify">Feminism has a bad reputation in Georgia, but I consider myself a feminist
      because I want men and women to have equal rights and equal relationships. Above all, I refuse to be perceived as
      weak. In Georgia, you can see inequalities everywhere. For example, if you cry that means that you are not a man.
      Or if you don’t sit in an elegant way, you are not a woman. I also feel these inequalities as a plus size woman,
      I’m always told that I should lose weight. There’s a very precise image of what a woman should be like in Georgia.
      But for men as well. You have to be strong, you cannot show your emotions, and you cannot be open to
      communication. </p>
    <blockquote data-aos="fade-up">In Georgia, men should be sexually active at a very early age and women should be
      virgins if they are not married. But then who are these men having sex with?
    </blockquote>
    <p data-aos="fade-up" class="justify">The good side of being a woman here is that men have a responsibility to take
      care of you. I believe a lot of girls like being treated that way, they like when a man holds the door open for
      them and pays the bills. But men feel pressured to act in such a manner, or else they will not be considered like
      real men. One time I went on a date with this guy, and I paid for the both of us when he went to the bathroom.
      When he came back, he freaked out but I tried to tell him it’s also nice when a woman takes care of the check. We
      went on a second date a couple days later and this time, he paid for everything. At the time, I thought he only
      took me out again because he wanted to pay me back and not because he actually liked me. It turns out it wasn’t
      the case, but I still thought about it.</p>
    <div class="pic-block ">
      <div class="pic-dispatch  portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">In Georgia, men should be sexually active at a very early age and women should
      be virgins if they are not married. But then who are these men having sex with? </p>
    <blockquote data-aos="fade-up">One of my classmates still didn’t know that women gave birth through their vagina!
    </blockquote>
    <p data-aos="fade-up" class="justify">On Facebook, there are secret Georgian women’s groups where they feel
      comfortable in sharing their sexual experiences. These facebook groups are actually very important because sexual
      education is abysmal in Georgia. When I was in 9th or 10th grade, and we were supposed to learn about human
      reproduction in biology class, our teacher told us to read about it at home. This happens all the time. When I was
      a senior, one of my classmates still didn’t know that women gave birth through their vagina! The problem though is
      that I see a lot of judgment on these Facebook pages. For example if you are a virgin, you’re going to be judged,
      but if you do too much you are going to be judged as well. You have to do something to be cool, but you cannot do
      too much.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">These groups were created by Georgian feminists to promote the empowerment of
      women’s sexuality. My biggest concern is that these posts become guidelines or rules on how to live. The group
      admins are too radical in my opinion, I don’t think I can say that they are open-minded even if they are
      feminists. Basically, for them, you have to be sexually active or else you cannot consider yourself a feminist. I
      don’t think that’s what a feminist should be defined as.</p>
    <blockquote data-aos="fade-up">The thing is, Georgia is a country that’s radical about everything: about food, about
      traditions, and even about feminism.
    </blockquote>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify"> I think the group is great for educational purposes, like for the sexual
      liberation of the traditional georgian housewife. By joining this group, she can learn that female sexual pleasure
      is important too. But the group leaders are dispensing rules on how to live, and they are followed blindly by
      their subscribers. The thing is, Georgia is a country that’s radical about everything: about food, about
      traditions, and even about feminism. Everything is either black or white, there cannot be any grey zones.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      You’re
      either one thing or the other. You’re either radically traditional or radically progressive. I think that’s too
      bad. One way I believe the admins are radical, is because they defend double standards in favour of women. For
      example, they’ll say it’s a woman’s right to refuse oral sex on men but if a man refuses then he’s misogynistic.
      But we can’t only defend women’s rights, we have to believe that men have rights too. A famous Georgian blogger,
      who I believe is really funny and smart because he’s quite cynical, was made fun of by the group for having a
      small penis. I thought that was super close-minded and rather humiliating. On the other hand, there are a lot of
      men who make fake profiles and come on the group to take screenshots of what is being said. When that happens, all
      the women subsccribers report the leaked conversations and they are always taken down. It’s a great testament to
      the kind of solidarity that can exist between women - especially in Georgian culture.</p>
    <p data-aos="fade-up" class="justify">
      I really believe that if women stand together, if we support and accept each other the way that we are, without
      judgement, we can change the world for the better.
    </p>
    <p data-aos="fade-up" class="justify">A lot of guys do think men and women should be equal, but they still behave in
      a sexist way. I used to think people from my generation were all open-minded. But with what happened at Bassiani*,
      I realised that wasn’t the case. When the police raided the club, a lot of young people took to the streets to
      protest peacefully for the right to be free and to make their own choices, but twice as many people of the same
      generation violently attacked these peaceful protesters while defending very conservative ideas. In this
      situation, religion was used to justify these acts: “it is God’s will, so we have a right to be violent”. That’s
      when I realised I had been living in a bubble, and I wrongly thought that all Georgian youths were progressive.
    </p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-9.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p><i>*Bassiani is the focal point of Tbilisi’s dance music scene. On May 12th, 2018 the club was raided by police.
      The officially stated reason for the raid is due to recent drug-related deaths and accusations of drug
      trafficking. However, many people believe the raid to be politically motivated since the club and its programming
      speak “loudly regarding the inequalities in the country”, as well as “the high level of injustice and
      discrimination” - especially in regards to the LGBTQ+ community. This raid led to a series of strong protests in
      the capital.
      <a href="https://www.nytimes.com/2019/05/29/arts/music/bassiani-tbilisi-georgia.html" target="_blank">Read more
        about it here</a>
    </i></p>
  </div>
</template>

<script>
export default {
  name: 'Anuka',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
