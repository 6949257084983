<template>
  <!--  1 Nutsa-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">My parents are separated. I lived with my mother until the age of 17, not too
      far from the
      neighbourhood where I now live with my father. I changed schools three times. The last time I changed schools, it
      was for two reasons. I wanted to learn German and my school at the time didn’t offer that, but also I was being
      bullied. What really stuck with me is that the teachers would always take the side of the kids who bullied me. In
      Georgia there’s a problem with women who don’t want things to change because they have suffered, and they believe
      young girls should suffer too.</p>
    <p data-aos="fade-up" class="justify">
      My family from my mother’s side was really influential in the development of my independent nature. My mother took
      care of herself and I did not see her often. Seeing my mother work so much has had a huge impact on me. Almost
      every woman from my mom’s side is separated or divorced from their husband and they live alone, which is not at
      all common in Georgia. So I come from a long line of strong women who work, who raise their children alone and
      that are independent. I’ve always understood that in order for me to be independent, I had to work. I started
      working at the age of 15. I worked for a Georgian TV show and we created short videos about Georgian youth
      culture. With this job, I was able to save some money to go to Turkey for a Pink Floyd concert. It was
      incredible!</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">
      I feel a responsibility towards Georgian women. I feel challenged to do things that people say women can’t
      do.
    </blockquote>
    <p data-aos="fade-up" class="justify">
      I have a roommate type relationship with my dad. We both take care of the household chores. We even split all the
      bills related to the apartment, which is also very unusual for Georgia. Neither my father nor my mother are very
      traditional. I don’t consider myself a traditional Georgian woman either. I chose to pursue a STEM career
      (Science, Technology, Engineering, Mathematics) and I hate doing housework. But I feel a responsibility towards
      Georgian women and I consider myself a feminist. I feel challenged to do things that people say women can’t do.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      Since I hate driving or playing football, I do other things. I also go to a lot of feminist protests in Georgia
      and I’m very active on Facebook, especially when I end up on pages with hate speech commentaries. Whenever I get a
      chance to say I’m a feminist, I say it. But with every protest, it’s always the same group of 50 people. A lot of
      them come to catch up with their friends. I feel sad that it’s always the same people. All my friends are
      feminists and I realize that I live in a bubble. Just the fact that I am able to attend a university, that’s
      already a privilege in itself.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">
      Many girls don’t know they have choices and options. Getting married at an early age is the only way they know
      how to live.
    </blockquote>
    <p data-aos="fade-up" class="justify">
      I regret not being more active during my adolescence because I think I could have changed some people’s views -
      like when I was in middle school and I was around people that were different from me, my bullies for example. So
      now I do try to step out of my comfort zone. I sometimes work as a counselor in summer camps for young kids or
      young girls that come from other regions of Georgia. I have a responsibility to inspire these girls and help them
      understand that they have the power to decide for themselves. Many girls don’t know they have choices and options.
      Getting married at an early age is the only way they know how to live. A lot of my friends are already living with
      their boyfriend and are not pursuing a career. I am afraid that they are relying entirely on their significant
      other. I don’t want them to end up stuck or without options. I’ve always heard my mother say how nice it would be
      if she had a husband that she could depend on, and that really stuck with me. I asked myself a lot of questions.
      Was my mother’s independence a choice? Or did she suffer from it? What is the best way? Either way, I always split
      the bill evenly with my boyfriend. I chose to be with someone that shares my progressive ideas. I support the
      LGBTQ+ cause and any other minority, and he does too.
    </p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      I’d love to be involved in the Tbilisi Gay Pride that is suppose to take place in May 2019*. But organizing it
      will be very difficult and dangerous. The organizers want the government to support them, but they will never get
      this support. In 2013, there was a very violent Gay Pride in Georgia. All the churchgoers came to attack the Gay
      Pride attendees. They only had one hour to march. There have been other Gay Prides since, but in 2018, it was
      cancelled. For the past 2 years, there has been a rise of right wing groups who are against foreigners and liberal
      views. They created Facebook groups against the organizers of the Tbilisi Gay Pride and that’s how it got
      cancelled. We still tried to have a clandestine Gay Pride march, but a right wing extremist savagely beat one of
      the participants. The aggressor faced absolutely no consequences. In fact he even received praise.
    </p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">
      I feel like bisexuality is more accepted in Georgia, because there’s the idea that a traditional male/female
      relationship is still possible.
    </blockquote>
    <p data-aos="fade-up" class="justify">
      I consider myself a centrist, sometimes I am politically more to the left and sometimes more to the right, it
      depends on the subject really. When it comes to my sexuality, I identify as bisexual. I feel like bisexuality is
      more accepted in Georgia, because there’s the idea that a traditional male/female relationship is still possible.
      I spoke to my father about my sexual orientation, but I am not sure whether he thinks it is a phase or not, at
      least he did not react negatively. From time to time, he makes homophobic jokes, but he isn’t violent. I’ve never
      told my mother because she’s a religious orthodox. I would only tell my mother if I found myself in a committed
      relationship with another woman. In a certain way, I am not very close with my family in the sense that I don’t
      seek their approval, we have more of an ‘adult’ relationship.
    </p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">
      Right now, most women's occupational roles in Georgia are concentrated in the educational sector, community care
      or customer service. These are all roles that are associated with nurturing. From my point of view, I see a lot of
      powerful women going into STEM fields. But when I step out of my bubble, like when I take part in summer camps for
      girls who come from remote places, I realise that when you live in Tbilisi you have so many more opportunities.
      For me, everything is correlated to the Internet. If you grow up without social media and without the Internet,
      your world can be very limited.
    </p>
    <blockquote data-aos="fade-up">
      Women in Georgia are very active but I always wonder if it’s because they want to be this way or if it’s because
      they don’t have a choice.
    </blockquote>
    <div class="pic-block">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-9.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-10.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      Many Gender researchers say that before the USSR, Georgia didn’t have all these gender equality problems. If you
      look at the first Georgian Republic, in 1918, women already had the right to vote. The Soviet Union rearranged the
      way labour should be organized. This brought forward a different perspective of the family unit. A lot of people
      say that according to Georgian traditions, a woman’s place is in the kitchen, but in my opinion this comes from
      the USSR. In the 90s, the men either went to war or became drug addicts. Our country went through very hard times
      with the two wars, so the use of narcotics became a big problem. The women weren’t affected in the same way; they
      took on all the responsibilities. Women in Georgia are very active but I always wonder if it’s because they want
      to be this way or if it’s because they don’t have a choice.
    </p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-11.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <i>*Activists in Georgia say they managed to hold a small gay pride parade in the capital, Tbilisi, on July 8
      2019.
      About two dozen protesters, including human rights activists and members of the LGBT community, protested outside
      the Interior Ministry while holding signs and rainbow flags. The rally lasted only 30 minutes amid reports that
      extremist groups were on their way to disperse protesters.
      <a
        href="https://us.boell.org/en/2019/12/11/despite-hosting-their-first-pride-2019-georgias-queer-community-still-vulnerable"
        target="_blank">
        Click here to learn more.
      </a>
    </i>
  </div>
</template>

<script>
export default {
  name: 'Nutsa',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
