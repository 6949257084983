<template>
  <!--  10 Leila-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">I was born here in Marneuli. Originally, no one lived here, it was just a
      crossing route for caravans who came to trade things. My great grandfather was one of the first people who settled
      here. He was born in Georgia, not too far from Rustavi, but his father came from Iran. His father was very rich.
      He was 70 years old when my great grandfather was born. His father was a womanizer and his wife was 18 years old
      when she gave birth. My great grandfather was raised in a madrasa - a koranic school. Later in life, he inherited
      a large house and a lot of land. After the russian invasion, the soviets took everything he owned even though he
      had 11 children to care for. The eldest child was my grandfather. I associate my childhood with my grandfather. I
      received my education in a normal soviet elementary and secondary school. I was so confused when the textbooks
      taught a certain version of Georgian history, and my grandfather would teach me a completely different version.
      The soviet textbooks would idealize the regime, but that interpretation was far from our reality. </p>
    <blockquote data-aos="fade-up">My great-grandmother was my hero.</blockquote>
    <p data-aos="fade-up" class="justify">I remember one particular story that my grandfather would tell me over and
      over again. A story about his mother and her beautiful soul. In the summer, they would take their cattle in the
      mountains to feed them because that is where the grass is the juiciest. One time when they were on such a walk,
      they were on the verge of coming across some marauders so everyone decided to run away. However, previously, my
      grandmother had hidden a box with some gold jewellery somewhere. As she was running, she remembered the box and
      decided to go back for it. She told her husband: “take the kids and leave, I will go alone”. She took a piece of
      cloth and put it over her head. As she was retracing her steps, she encountered one of the marauders. She
      pretended to be an old lady looking for her cow and he let her go.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      My great-grandmother was my hero. For me, she
      was an example of how women could be smart and courageous. From that moment on, I realized that even if women were
      considered the ‘weaker sex’, from what I could see they were actually really strong. The full realization of the
      strength of women came later in my life, but all these stories that my grandfather told me, they planted a seed in
      my head.</p>
    <blockquote data-aos="fade-up">People here will support ‘women’ if it is a hypothetical woman that they don’t know
    </blockquote>
    <p data-aos="fade-up" class="justify">When I am asked what roles women occupy in Georgia, I have two answers: how it
      is and how I would like it to be. The reality is that this is a male dominated society and that women’s roles are
      secondary. Women are usually mothers, housewives, wives. So basically, anything that is secondary. </p>
    <div class="pic-block ">
      <div class="pic-dispatch duo landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">Nobody is willing to imagine that women can be leaders. Even though some
      people claim that we have gender equality, actually it is still not the case even inside families that we can
      consider to be ‘progressive’. There is a double standard. People here will support ‘women’ if it is a hypothetical
      woman that they don’t know, but if it’s someone they are close to, their relative for example, they will not
      believe in her or support her in pursuing a life where she can be free. They are aware of the idea of gender
      equality and they accept it, but they don’t want to apply it to their own community. It is not only the men’s
      fault, it is also the women’s fault. It is the fault of the community as a whole. It is a mentality that everyone
      subscribes to. If things were different, if they were how I want them to be, I believe sex or gender would be
      irrelevant. What matters is someone’s personality. If you are ambitious, it doesn’t matter if you are a man or a
      woman. I think everyone should be able to achieve their goals regardless of their gender. An ideal world for me
      would be a gender neutral world. If that was the case, a lot more women would be able to appreciate themselves and
      they could see themselves thrive in any domain. Only then would we have a truly balanced society. But we are still
      very far from achieving this balance since even our parliament, who keeps promising gender equality, doesn’t have
      gender parity.</p>
    <blockquote data-aos="fade-up">As much as there are not many women in politics in Georgia, there are even fewer
      ethnic minority women
    </blockquote>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify"> If you look at any government position, you will see that there are very few
      women. Salome wasn’t elected fairly, and I say this as an election observer. These elections were not fair. She
      was placed there. It is unlikely that women living in Georgia will get inspired by Salome since everyone knows she
      wasn’t elected fairly. She doesn’t deserve her election, everyone knows she is Ivanishvili’s pawn. But, as much as
      there are not many women in politics in Georgia, there are even fewer ethnic minority women, except maybe for one
      person that I can think of. In another village not far from here, a local representation position opened up and a
      woman applied for the job. Her candidacy was supported by the entire community. But she is Azeri, and so an ethnic
      minority. The mayor, who could have appointed her, used her minority status against her to deny her the job. The
      mayor was ethnically Georgian of course, and even if she was supported by her entire community, he refused to
      appoint her.</p>
    <blockquote data-aos="fade-up">This time, I took it so personally that there weren’t any women, I entered the
      election as an independent candidate.
    </blockquote>
    <p data-aos="fade-up" class="justify">Since 2003, I’ve been working on election observation projects both in Georgia
      and internationally. In 2017, during Marneuli’s municipal elections, there were two major rival political parties
      but neither of those parties had any women candidates. The way the system works is: every party has a list and in
      every list, only the first 20 names have a real shot at becoming Mayor or at least a member of the municipality.
      Although these two parties are always defending the idea of having women in politics, they didn’t have any women
      in their first 20 candidates. Here is a tangible proof of what I was talking about when I mentioned the double
      standard - a lot of things are said, but nothing is applied.</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">Before these elections, I had been offered to become a candidate many times,
      but each time I refused. This time, I took it so personally that there weren’t any women, I entered the election
      as an independent candidate. I signed up at the last minute. I knew I was never going to win, but I wanted to show
      that women can run for the position of mayor. I wanted to be an example for other young women that are oppressed
      by their families or their communities, so they could have a concrete example of a woman who is running in an
      election. I think it is very natural for women to want to be leaders. It was also a sign of protest against the
      other major parties that did not include women in the race. I did it so they couldn’t hide behind excuses and say:
      ‘we don’t have candidates that are women because women aren’t invested in politics’. Yes we are, the parties just
      don’t want to nominate us. </p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">This stirred up a lot of discussions, and not just at the local level but at
      the national level too. It surprised a lot of people that an Azeri woman did that. People talked about me a lot.
      And it wasn’t all positive comments, there were a lot of negative comments too. For example, there is this liberal
      newspaper that published an article about me and I read some comments online where people were telling me things
      like ‘go back to your country’. There were some racist and sexist comments, but I can’t remember them anymore.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-9.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">For me feminism means fighting for women’s rights and I stand by that meaning.
    </blockquote>
    <p data-aos="fade-up" class="justify">In western societies, feminism is a lot more developed whereas here it is only
      starting. In Europe and in the United States, the law is on women's side. In Georgia, even if the law dictates
      gender equality, it is not applied. There is no political will to enforce these laws. This is in part due to the
      fact that this country has been long stuck in the Soviet Union, and even before that it belonged to the Russian
      Empire. A lot of people say that before the USSR, Georgia was a country where gender equality was respected but I
      am not sure how correct that assumption is. It is true that there is a historical precedent with Queen Tamar which
      was quite progressive for her time, and maybe that’s where this myth comes from. Maybe it’s because women have
      always been respected as mothers in Georgia, even if their role is limited. </p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-10.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">Officially, the head of the family is the man, but women participate in the
      decision making process. Men are the ‘face’ of the family, but women are often running things in the background. I
      suppose I am a feminist if that word means what I think it means, which is to fight for women’s rights. In
      Georgia, feminism has a bad connotation because of the incidents related to radical feminism. For me feminism
      means fighting for women’s rights and I stand by that meaning. I don’t fight for ridiculous things like if a man
      has to open the door for me or not, I fight for bigger things.</p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-11.jpg')"
          :alt="womenName">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Leila',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
