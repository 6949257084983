<template>
  <!--  5 Irina-->
  <div class="text-content">
    <p><i>*The vast majority of the population in Ninotsminda are ethnic Armenians.</i></p>
    <p data-aos="fade-up" class="justify">I was born in Ninotsminda, and I can say that my childhood was quite
      interesting. I was a very
      active child. I liked spending time with my dad because I felt close to him. I felt closer to him than I did to my
      mom. I love my mom of course, but I am closer to my father. My mom didn’t work at that time. She was busy at home
      with domestic chores. So I did get to spend a lot of my time with her, and my grandmother as well. I gained a lot
      of knowledge from my dad, he taught me the rules of etiquette because he worked with officials - he was as a
      police officer. I grew up with the feeling of being very free and pampered, I was always allowed to do exactly
      what I wanted.</p>
    <blockquote data-aos="fade-up">I was one of the first girls in my community that could drive. Now, there are only
      about 20 girls that know how to drive here.
    </blockquote>
    <p data-aos="fade-up" class="justify">When I was about 1 or 2 years old, my father would take me on his lap while he
      drove and he
      would let me touch the wheel. I liked the feeling of driving so much that when my feet could finally touch the
      pedals, he let me drive by myself. I was one of the first girls in my community that could drive. Now, there are
      only about 20 girls that know how to drive here. One day, one of our relatives from my dad’s side told us that it
      was not okay for a girl to drive, that her place is not behind the wheel. He thought that instead, I should be
      preparing for marriage and learning how to look after a home. This is still how a lot of people think in my
      community. My dad told him: ‘that’s no one else’s business, and I want my daughter to grow up to be a strong and
      independent woman.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer medium"></div>
    <p data-aos="fade-up" class="justify">
      My father never imposed any restrictions on the things I was allowed to do. He would only warn
      me: ‘if you do this, this is what could happen’. But he would let me experience things on my own. Sometimes things
      turned out well, and sometimes they didn’t. But that’s how I learned what I was capable of. For example, for a
      while I experimented with wearing short skirts and tight tops, but that made me feel uncomfortable after some
      time. I never asked him permission to go anywhere. I would simply say ‘I am going’ and that was enough for him.
      The main thing is that my parents trusted me. That was very important to me because I didn’t want to disappoint
      them. I didn’t want my actions to reflect badly on them or make them feel ashamed in any way. I think this is the
      main reason why I always was so responsible. You won’t find this type of situation in many families living in the
      region. In most cases, fathers don’t let their daughters do what they want and they keep them at home. Growing up,
      I felt like a ‘white raven’ as opposed to being a black sheep.
    </p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer"></div>
    <p data-aos="fade-up" class="justify">I attended the Russian school and, until the 8th grade, I was a good student.
      But after that,
      I don’t know, I lost interest in school. I only studied for subjects that I liked, like Georgian, biology, physics
      and chemistry. I wanted to be a doctor. Then I changed my mind to foreign languages in order to become a
      translator. I thought that it would take me on the path of an international career. I was very interested in
      travelling. But my family stumbled upon some financial problems, and I stayed in Ninotsminda where I began
      studying law. Two years later, I got married and I had to get a job immediately working as an operator in a
      factory. I went on maternity leave for my first child and we moved to Russia for a year. My husband didn’t like it
      there so we came back to Georgia. I wanted to find work, but there was nothing for me, and then I had my second
      child. It was hard for us to live off of one salary, my husband’s salary. I looked for work everywhere. I even
      applied to work at the fire department.</p>
    <blockquote data-aos="fade-up">Life here is full of stereotypes, but people think these stereotypes are
      traditions.
    </blockquote>
    <p data-aos="fade-up" class="justify">My current director was my family’s acquaintance; she is the wife of my
      father’s friend. She
      heard that I was looking for work, she knew me well since I was one of her previous students. We were very close.
      She offered me a job remembering my efforts as a volunteer when the centre first opened up. She’s a very strong
      and smart woman. We talk a lot with each other, and often I have the impression that what she’s saying could come
      straight from my soul. The things that I want to express, she formulates them so clearly into words. Before I
      started working at the centre, I wanted to be like everyone else because it was hard for me to be different. But
      my director really helped me. She said: ‘be yourself, don’t change because you belong to the strand of people that
      are able to move forward and reach their goals, this is your personality’.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      When I started this job, I didn’t know what I was doing. I didn’t know much about gender equality, feminism,
      tolerance and integration. I was working and learning at the same time. My director helped me a lot in the
      beginning. I’ve always wanted to play a role in changing the mentalities of the people around me. I also want to
      help people that I feel have a lot of potential, I want to help them move forward and achieve their goals. This is
      what I do with the young people that I work with.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      Often I see that they want to do something special with their life, but they seem to encounter a lot of obstacles,
      and sometimes those obstacles are their parents. I really want to improve the situation in Ninotsminda and in the
      villages, because for women life does not come easily. Life here is full of stereotypes, but people think these
      stereotypes are traditions. For example, there’s the idea that a woman should only be concerned with her children,
      cooking, cleaning and nothing more. Education and work is considered not important for women.</p>
    <blockquote data-aos="fade-up">Young girls here get married as early as 14 years old.</blockquote>
    <p data-aos="fade-up" class="justify">I can say with certainty that out of all the young people who attend our
      trainings at the
      centre, none of them will become victim of an early marriage. We talk a lot about this issue at the centre.
      Marriage is a very important step in someone’s life and you need to have enough maturity to be prepared for it -
      young girls here get married as early as 14 years old. Even if you are 18 or 19 years old, you can’t know if one
      person is right for you. You might feel like this is your person, but you might be wrong because at that age you
      are only feeling it with your heart and your hormones. </p>
    <blockquote data-aos="fade-up">You can’t control an adult woman the same way that you would a young girl.
    </blockquote>
    <p data-aos="fade-up" class="justify">In our region, especially in the villages, it happens often unfortunately.
      It’s happening less
      because people are starting to understand the importance of higher education. But if a family doesn’t have the
      money to send their children to university, they have them get married and that mostly concerns the girls. Some
      men prefer to take a young wife because he’ll be able to control her more easily. She will not have a lot of
      experience and won’t be able to stand up for herself. You can’t control an adult woman the same way that you would
      a young girl.</p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">Unfortunately in Georgia, even if there is progress, it is very slow and
      limited. I see women
      integrating the job market, but in the private sphere things have not changed. There are exceptions, families that
      practice gender equality, but the majority of families are holding on tight to their traditional gender roles. You
      can’t change an adult person, so the work needs to be done around youth. Maybe then they will raise their children
      in a new way. I am almost certain it will be like that. When I look at the current youth, I can see that they are
      different. They are freer than our parent’s generation and ours. And this means that, with a little bit at a time,
      things will start to move forward. But it is also necessary to reform the current school system, because our
      schools are still stuck in the Soviet era.</p>
    <p data-aos="fade-up" class="justify">I believe that we cannot be equal to men in every way, since every one of us
      has different abilities, strengths, weaknesses, and potential. I consider myself to be an egalitarian person, and
      it doesn't
      matter what my sex is.</p>
    <p data-aos="fade-up" class="justify"> I don’t think I really consider myself a feminist because for me feminism
      somehow implies that you don’t respect the male gender. I am for gender equality.</p>
  </div>
</template>

<script>
export default {
  name: 'Irina',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>

  .text-content {

  }
</style>
