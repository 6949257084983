<template>
  <!--  7 Manana-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">I grew up in Kutaisi, the capital city of the region of Imereti. This is where
      I attended a Russian school starting from kindergarten. This was around soviet times, everything was in Russian so
      my parents thought it would be helpful for my future. The first time I heard English, I immediately fell in love
      with it and decided to become an English teacher. When I was in 10th grade, my mom asked me what I wanted to be
      when I grew up, she was surprised when I told her I wanted to be an English teacher because she thought Russian
      was better. In the early years of my marriage I won an English contest with an opportunity to go to the United
      States for a 2 week training. At the time my mother-in-law who was living with us was very ill. I felt like it was
      my duty to stay and take care of her. But my husband told me: “you won this opportunity, you should go, I will
      take care of her”. I feel very lucky that he took on that responsibility for the 2 weeks that I was away, because
      this trip was one of the best experiences of my life !</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">I am well known in my community because I started a yearly tradition in
      Baghdati where we host a festival for Georgia’s independence day. I also started a female basketball team and
      received funding to organize female basketball tournaments. In the last tournament, 9 teams competed against each
      other and my team won the cup. I started all these activities with the help of an American colleague from Peace
      Corps*, her name is Ari and she worked with me for over two years. I could not have done it without her help.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">I am not the type of person that can afford to be lazy.</blockquote>
    <p data-aos="fade-up" class="justify">I like being active because it takes my mind off of all the negative things
      that could be going on in my life. When you are engaged in an activity, you don’t have the time to think about
      negative things because your mind is concentrated on the task at hand. When I’m occupied with things to do for my
      family or my community, I am not focused on myself. I don’t think about all the bad things that could happen to
      me. I am not the type of person that can afford to be lazy. If I am not doing something, my anxiety will start to
      grow and all I can think about is my backache, my headache and all the other things that ache. When you’re active,
      you just think about the next day, the next steps, and in my case that keeps me upbeat. Activity puts me in a good
      mood.</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">I am the manager of the innovation centre and I teach English at school and at
      home with my private students. When I am not working, I am busy lesson planning and planning projects for the
      innovation centre around English, public speaking, and other topics that youths could be interested in. I am in
      charge of scheduling all sessions and training at the innovation centre. In 2018, I won a National Teacher Prize,
      which is held by the ‘Education for All’ association. The National Teacher Prize was started in 2017 in Georgia,
      and there are 10 ‘Best Teachers’ named each year. Together with all the ‘Best Teachers’ of 2017 and 2018, we
      formed the ‘Teachers Club’. We try to come up with interesting activities, games, songs that could engage the
      kids. We want our activities to encourage critical thinking. At home, I manage a wine cellar. My husband works in
      the vineyard, and
      I’m responsible for entertaining tourists when they come. In the evening, I sit at my laptop and plan for the
      lessons and all the other activities I will be leading the next day. I usually get to go to sleep around 2am and
      wake up at 7am. Most of my professional skills, I have learned by myself. When you know English, learning things
      is easier with the Internet. My sons helped me to cultivate my computer skills though. The first time I saw a
      laptop was in 2000. I started typing letter by letter.</p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">I am religious but I don’t go to church every Sunday. Sunday is my only day
      off so I need to sleep. However, I never go to sleep or wake up without saying a prayer. My grandmother was a very
      religious woman. She shared with me this religion. When I was a little girl, she talked a lot about religion with
      me. She used to make me candles that were my size so I could light them and pray. My grandmother was the ideal
      woman for me. My mom was at work all the time. My grandmother taught me everything I know: knitting, reading, and
      embroidery. She taught me how to behave, how to talk to people, and how to be religious. These were the communist
      times so we were not allowed to have crosses. My grandmother used to tell me: ‘you have to carry your cross in
      your heart to be safe’. I would secretly sow a cross inside of my clothes. I thought it would protect me from
      every bad thing. I would never go to bed without praying. This comes from my childhood and I have never stopped.
      In my own opinion, women are more religious than men in Georgia. Even though the entire family will be involved,
      women tend to be more involved. Some women I could even categorize as fanatics. I don’t know why that is. Maybe
      because the men feel like they are stronger than us and that women should obey them and only go to church.</p>
    <blockquote data-aos="fade-up">I try to push the girls in my community to have goals and to pursue them.
    </blockquote>
    <p data-aos="fade-up" class="justify">I try to always interact with boys and girls with gender equality in mind, but
      when girls ask me for something I am more eager to help. This is why I started organizing sensitivity training on
      gender roles and stereotypes. I don’t really think it’s harder for women to achieve their goals. I believe that if
      you have ambition for yourself, you will make it. Women here simply have no ambition, all they want to do is to
      finish high school and get married. I try to push the girls in my community to have goals and to pursue them. From
      my point of view, this lack of ambition comes from the environment in which these girls are raised in. For
      example, if a girl comes from a family that doesn’t read and doesn’t travel, that family will be more inclined to
      push their daughter to finish high school and get married right away. If a family is more educated, they will push
      their daughter to pursue a higher education. As teachers, this is how we have to try to help young girls. But it’s
      hard. If their own mother got married at a young age, these girls will think it is a type of tradition and that’s
      how things should be. </p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">This is why workshops about gender roles are highly important, they influence
      girls to think differently. The trend right now is definitely about pushing girls to study. Mothers are often
      immigrating in foreign countries, working as housekeepers to support their children’s education. Girls’ education
      has become an important subject in Georgia, also because it is a global trend. We can see it on TV, on the
      Internet. Everyone has access to the Internet.</p>
    <blockquote data-aos="fade-up">Some young men are starting to live alone after leaving the family house. They learn
      how to cook, clean and to look after themselves.
    </blockquote>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-10.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">More and more young people have begun working part-time while they attend
      university and I think that has had a positive influence as well. Some young men are starting to live alone after
      leaving the family house. They learn how to cook, clean and to look after themselves. This is also contributing to
      the evolution of gender roles in our society.</p>
    <blockquote data-aos="fade-up">When I met my husband, he asked me: “why do you look people in the eyes when you talk
      to them?
    </blockquote>
    <p data-aos="fade-up" class="justify">When I met my husband, he asked me: “why do you look people in the eyes when
      you talk to them?”. Georgian women still feel like they need to look down when they are talking to someone or
      someone is talking to them. I hope that women will start to be treated equally and that we can achieve equal pay
      one day. The new Mayor of Baghdati recently lowered my pay as Manager of the Innovation Center and I think it is
      because I am a woman. </p>
    <div class="pic-block ">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-9.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">I don’t consider myself a feminist, but I don’t consider myself not a feminist
      either. I am for the idea that women and men are equal. At home, when I don’t have time to cook, my husband is the
      one that takes over. My son got married this summer and they divided the chores equally with his wife. I don’t
      know if they would have done that if he hadn’t been raised this way. My husband doesn’t like it when I tell other
      people that he can cook, but I am an honest person and I don’t see the problem in telling them the truth. In
      Georgia, we had a really strong feminist movement emerge a couple months ago. I am really happy that we have so
      many women leaders in our government, it shows that Georgia is becoming a strong country. I am not for the idea
      that women are the best and that we don’t need men, women alone cannot change everything.</p>
    <p data-aos="fade-up" class="justify"><i>*Peace Corps is an independent agency and volunteer program run by the
      United States Government providing international social and economic development assistance. The Peace Corps has been
      implementing development programs in Georgia since 2001.</i></p>
  </div>
</template>

<script>
export default {
  name: 'Manana',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
