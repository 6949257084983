<template>
  <!--  6 Nargizi-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">I was born in the 1950s, in the small town of Samtredia because my father
      worked there at the
      time. A little later we returned to Nigvziani, the village where my parents were originally from. I stayed there
      until I finished high school in 1966. After that, I started studying foreign languages in Tbilisi. I graduated in
      1972 and ever since then, I’ve been teaching English in Georgian public schools. I’ve only changed schools three
      times. I taught in Nigoeti, in Nigvziani and then at the third public school in Lanchkhuti, but always in the
      region of Guria. I also worked as a school supervisor for 22 years at the Nigvziani secondary school.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <blockquote data-aos="fade-up">It was my personal decision to get married later in life.</blockquote>
    <p data-aos="fade-up" class="justify">My parents were the ones who insisted that my sister and I get a higher
      education. My sister
      studied economics. I met my husband when I had already been working for 5 years. When we met, we immediately
      decided that we wanted to get married and start a family. I was 27 years old. At the time, most of the girls got
      married at an earlier age. It was my personal decision to get married later in life. We had two children, a son
      and a daughter. My son works as a veterinarian. My daughter in law is also a teacher and they have 4 children
      together.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">In the village where I am from, there was a time where we welcomed refugees
      from the region
      Adjara because of a flood. So the village is currently a mix of Gurians and Adjarians. In the beginning, the
      integration process did not go smoothly. The Adjarians refugees were Muslims. These women were different from us.
      They were not allowed to talk with men and they had to cover their heads with a headscarf.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-5.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      We, as orthodox Georgian women, we’ve never had to limit ourselves in our lives, in our jobs, we’ve always been
      free. Things became easier with time and people became friendly with one another. A little later, they began to live like us,
      they even converted to christianity. Now there are no more differences between us. Ever more so today, all
      Georgian women are free. We can easily get a job like any other man.</p>
    <blockquote data-aos="fade-up">According to Georgian tradition, men shouldn’t participate in household chores. I
      don’t think this is normal.
    </blockquote>
    <p data-aos="fade-up" class="justify">However, it’s true that according to Georgian tradition, men shouldn’t
      participate in
      household chores. I don’t think this is normal. I think men should help their wives in every aspect of life. In
      raising children, in doing household chores and yard work. I tried to raise my kids with these ideas, but my son
      is not very good at it. He still thinks it’s a woman's job to do these things. In that regard, things are changing
      a little bit, I see more men helping women. In my house, the men are the ones who are responsible for maintenance.
      My husband is the one who fixes things.</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">In our country, religion carries a real significant weight. You could say that
      in many ways
      the church even influences our government. Very often the government and the religious authorities will have
      meetings to discuss important issues. I’d say religion influences women as much as the women themselves are
      religious. Most orthodox women try to keep the roles and customs dictated by their church. Those rules vary from
      church to church, but in most of them women can’t wear pants or short dresses. Because of the nature of the
      Orthodox Church, women are much more influenced and restricted by religion than men. I think the existing gender
      inequalities are linked to religion. As a rule of thumb, men have always had the advantage of deciding things for
      themselves without needing their spouse’s approval. On the contrary, women are expected to take orders from their
      husbands and they need permission to do certain things. This situation might not be completely exact for most of
      us, but you can still see some indirect traces orthodox norms in our daily life.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">Feminism as a concept means a lot to me, even from the time when the word
      ‘feminism’ meant
      nothing in Georgia. From a very early age, self-development through feminism has been extremely important to me.
      For me, it means that I am able to express myself fully. It is my right to do what my heart desires. Whether that
      means being a student until you are 40, giving birth after a certain age or not having children at all. If I were
      to be frank, I feel like nowadays Georgia is a covert matriarchy. Most Georgian families get their main source of
      income from women. However, looking at modern Georgian feminists, I don’t see them as a representation of real
      women. I think their views are very radical. It’s like they are trying to replace men. I don’t think there should
      be a superior gender. I think we all are equal. Sentences like ‘god is a woman’ and ‘future is female’ seem very
      radical to me.</p>
    <div class="pic-block">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-9.jpg')"
          :alt="womenName">
      </div>
    </div>
    <blockquote data-aos="fade-up">I believed in her because she’s a woman and that was the first time we could vote for
      a female candidate. That’s why I put my trust in her.
    </blockquote>
    <p data-aos="fade-up" class="justify">For me, It is not a question of men versus women, but it’s about poor versus
      rich. There are
      families that are so poor that they can’t provide for their children. My wish is for women to gain more economic
      power so that they can give a good education to their children. There’s still a lot of poverty, not everyone has
      the opportunity to do what he or she wants. I think the government should be closer to the people, understand what
      they need and what they want. The government is here to serve the people, not the other way around. It is unfair
      that our representatives benefit from high salaries when there are people in this country who don’t even have
      enough money to buy food. I think the government tries to help, but it is not enough.
    </p>
    <div class="pic-block ">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-10.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">
      Something needs to change. I personally voted for Salome because I had the understanding that she would help us
      better than any other
      president, and I trust that she will. I believed in her because she’s a woman and that was the first time we could
      vote for a female candidate. That’s why I put my trust in her.</p>
  </div>
</template>

<script>
export default {
  name: 'Nargizi',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
