<template>
  <!--  8 Marina-->
  <div class="text-content">
    <p data-aos="fade-up" class="justify">I was born and I grew up in Tbilisi. I was born at the time of the Soviet
      Union. Things were very different back then. For example, men and women had equal pay. During this time, women
      worked just as much as men because unemployment was illegal. But maybe women were in fact busier since, on top of
      having a job, they were the ones taking care of the house. I think that when the USSR collapsed, Georgian men
      found it very difficult to adapt and a lot of them turned to alcohol, but I cannot say this with certainty. The
      things that I hear about today, I did not witness them when I was a little girl specifically because I grew up in
      Tbilisi. I remember a girl from my school that got married at the age of 15, and everyone was talking about it. It
      was not considered normal.</p>
    <div class="pic-block ">
      <div class="pic-dispatch duo portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-2.jpg')"
          :alt="womenName">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-3.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">I went to school like all little children do, and then I entered university to
      study psychology. I was interested in that field because, above all, I was curious to know myself. Why I did
      certain things and other people did differently. My mother wanted me to become a doctor because she was a doctor
      herself, but I chose my career. After consideration, I think my desire to study psychology was influenced by my
      family. I was the youngest, I had a big sister and a big brother, and I was considered to be a spoiled and
      stubborn child. I questioned everything, and maybe the people around me were the reason that I wanted to
      understand myself. </p>
    <div class="pic-block ">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-4.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">I was considered to be different because I was in love with the idea of
      travelling. I read a lot. I wanted to gain an understanding of the political climate at the time. I saw
      limitations to the Soviet Union. I was reluctant to go to school everyday and often times I would skip. In school,
      they really insisted on discipline and I didn’t understand why things had to be this hard. There was always a very
      strict hierarchy: director, teachers and then way down at the bottom, students. There was always a reason to
      receive criticism, get yelled at, relationships were very negative. Now that the educational system is completely
      different, I understand why discipline is necessary.</p>
    <blockquote data-aos="fade-up">During all this time, this idea was blooming in my mind, and finally in 2006 I told
      myself: "it will be me, I have to do it".
    </blockquote>
    <p data-aos="fade-up" class="justify">My academic education was severely based on theory – a lot of theory and very
      little practice. When I finished my studies, I had the impression that I didn’t know my field, that I hadn’t
      gotten any training. I spent quite some time at the Georgian Institute of Psychology as a researcher, and this was
      a time where I was barely doing anything. Psychologists would come to the institute, I would teach them about
      psychoanalysis and they would all say ‘that’s nice’ but I realized that they weren’t able to do anything with it.
      It took me 10 years to understand that I would be the one to introduce the practice of psychoanalysis in Georgia,
      and for 10 years our French colleagues would come help us in Tbilisi. They would organise seminars, they would
      receive children for therapy under the scope of a program from the French Ministry of Foreign Affairs. This was
      the basic foundation for the development of the organisation that I founded - the Green House. During all this
      time, this idea was blooming in my mind, and finally in 2006 I told myself: ‘it will be me, I have to do it’. I
      joined forces with my friends Maya and Irina. Now we are a larger group of many women and one man. This
      organization was truly founded by women.</p>
    <div class="pic-block ">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-7.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">When I went to Paris, I was able to observe the system of psychoanalysis and
      how they worked with women, men, children, pregnant women and babies. We decided that we wanted to anchor our
      project, the Green House, in this system. The Green House is a centre for children, as soon as they are born, for
      the prevention of psychological disorders. It is mainly for kids, but very often they are accompanied by their
      mother or their nanny so we also have to take care of them. The situation is very different in Tbilisi than it is
      in the other regions. Here parents come willingly whereas in the other regions, parents prefer not to face the
      facts of their child’s condition. This is why we created a centre that focuses on prevention, when the problem is
      not too advanced yet. But quickly, I had to face another reality. I was taking in parents who came to the centre
      with their child when his or her disorder was already too advanced, and they came because they could no longer
      hide his condition.</p>
    <blockquote data-aos="fade-up">I remember this girl who was my classmate, when people would ask her father how many
      children he had, he would say: “I have one child and two girls".
    </blockquote>
    <p data-aos="fade-up" class="justify">During my childhood, I didn’t notice a difference in the way boys and girls
      were being raised. Maybe these inequalities existed, but not in Tbilisi, maybe in the other regions. I remember
      this girl who was my classmate, when people would ask her father how many children he had, he would say: “I have
      one child and two girls”. Maybe my lifestyle was not well accepted by others, maybe my friends’ parents were a bit
      exasperated by the fact that I didn’t have a curfew, but I never felt this exasperation coming from my parents.
      Maybe my lifestyle was influenced by the university that I attended, which was considered the main hub for
      freedom. In our university, they tried to ban women from wearing pants, but they never managed to do so whereas in
      other universities this is what happened. I never really felt very feminine anyways.</p>
    <div class="pic-block full">
      <div class="pic-dispatch landscape" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-6.jpg')"
          :alt="womenName">
      </div>
    </div>
    <div class="spacer big"></div>
    <p data-aos="fade-up" class="justify">Nonetheless, I cannot say that we have achieved equality. The situation is
      probably different in the bigger towns. Maybe even amongst the new generation things are no longer the same, but I
      know a young girl who told me that she doesn’t want a husband that is able to do the dishes because she views it
      as shameful. For me, these inequalities come from Russia. In Georgian culture, the woman is powerful. There is
      even a tradition by which a woman can stop two men from fighting by simply waving a flag. After the Russian
      invasion, this tradition was no longer respected. I myself grew up in a family where I didn’t notice inequalities.
      I cannot say that my mom took care of me more than my dad, even though she often complained that she was the only
      one taking care of me.</p>
    <blockquote data-aos="fade-up">The nature of Georgian women is not to be obedient.</blockquote>
    <p data-aos="fade-up" class="justify">The only good thing that came from the Soviet Union is equality in labour;
      there was a lot of propaganda for this equality. In the streets, there were a lot of posters showing women driving
      tractors. I remember a specific example: my oldest sister was studying mathematical linguistics and when my French
      cousin came to visit, she asked her what she was studying. My cousin was so surprised and told us: “but isn’t that
      too complicated for a woman?”. I was so taken aback by that question because we really didn’t see things like
      that. After the collapse of the Soviet Union, religion became even stronger here. People turned to the church in
      face of hard times and this influenced people to turn to really old traditions. </p>
    <div class="pic-block ">
      <div class="pic-dispatch portrait" data-aos="fade-up">
        <img
          :src="require('@/assets/img/women/' + womenId + '_' + womenName.toLowerCase() + '/pics/Women-of-georgia-' + womenName + '-8.jpg')"
          :alt="womenName">
      </div>
    </div>
    <p data-aos="fade-up" class="justify">Sometimes I hear people saying that women can’t do this or that, and before I
      didn’t hear these things. For example, for men today, there is the idea that men cannot do certain things like the
      dishes for example, and during my childhood that wasn’t the case. In fact, it’s also in the 90s that we really
      started to hear about these kidnapped wives stories*, not before. But we are already seeing that Georgia is
      evolving rapidly. The nature of Georgian women is not to be obedient. That is my opinion. Women have managed to
      grow more and more independent. The problem for me is still education. The level has gone so down, especially in
      the other regions, and the mentalities are not evolving as fast as they should. I also think that men are
      particularly impacted by unemployment, and if the economic situation changes maybe the overall situation will
      improve. But the most important thing is education.</p>
    <blockquote data-aos="fade-up">Now Georgia has elected its first woman president. Today, this might not be very
      symbolic, but maybe with time this election will have a deeper meaning.
    </blockquote>
    <p data-aos="fade-up" class="justify">In Georgia, we have a lack of women in positions of power, but this problem is
      true worldwide. Change is coming. We are seeing more and more women joining parliament for example. Even if there
      aren’t a lot of women in positions of power, we never thought that being a woman was an obstacle to becoming
      someone. Now Georgia has elected its first woman president. Today, this might not be very symbolic, but maybe with
      time this election will have a deeper meaning, and maybe it will influence girls and give them the ambition to
      pursue positions of power.</p>
  </div>
</template>

<script>
export default {
  name: 'Marina',

  props: {
    womenId: {
      type: Number,
      default: 1
    },
    womenName: {
      type: String,
      default: ''
    },
  },

  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  .text-content {

  }
</style>
